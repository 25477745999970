<template>
  <loader />
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Logout',
  methods: {
    ...mapActions('app', ['doLogout'])
  },
  async created () {
    this.doLogout()
  }
}
</script>
